<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: 'contratantes.view',
              params: { id: $route.params.contractor_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} | ${
                loading_contractor ? '' : `${contractor.name}`
              }${data.length == 0 ? '' : ` (${total_rows})`}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                :loading="btn_xls_ldg"
                :disabled="data.length == 0"
                @click.prevent="downloadXls"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLS'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.insureds_migration.create"
            tLabel="Migración"
            tPosition="bottom"
            icon="mdi-file-table"
            color="warning"
            size="x-small"
            :to="{
              name: 'asegurados.migracion',
              params: { contractor_id: $route.params.contractor_id },
            }"
          />
          <BtnCircle
            v-if="login.role_id == 1"
            tLabel="Recalcular"
            tPosition="bottom"
            icon="mdi-clipboard-account"
            color="pink darken-2"
            size="x-small"
            :to="{
              name: 'insureds.recalculate',
              params: { contractor_id: $route.params.contractor_id },
            }"
          />
          <BtnCircle
            v-if="login.permissions.insureds.create"
            tLabel="Crear"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{ name: `${page_route}.store` }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" xs="12" md="2">
          <v-select
            label="Revisión"
            v-model="revision"
            :items="revisions"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            @change="getTableData"
          />
        </v-col>
        <v-col v-if="revision != null" cols="12" xs="12" md="2">
          <v-select
            label="Tratamiento médico"
            v-model="medical_treatment"
            :items="medical_treatments"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            @change="getTableData"
          />
        </v-col>
        <v-col v-if="data.length > 0" cols="12" xs="12" md="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
          >
            <template v-slot:item.user_id="{ item }">
              <v-icon
                small
                v-text="item.user_id ? 'mdi-check' : 'mdi-close'"
                :color="item.user_id ? 'success' : ''"
              />
            </template>
            <template v-slot:item.index="{ item, index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.medical_treatment="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    :color="
                      (item.medical_treatment ? 'pink ' : 'grey') + ' darken-1'
                    "
                  >
                    mdi-shield-account
                  </v-icon>
                </template>
                <span
                  v-text="
                    (item.medical_treatment ? 'Con' : 'Sin') +
                    ' tratamiento médico'
                  "
                />
              </v-tooltip>
            </template>
            <template v-slot:item.revision_pending.revision_user="{ item }">
              <div v-if="item.revision_pending" class="text-center">
                <v-icon
                  small
                  :color="
                    item.revision_pending.revision == null
                      ? 'warning'
                      : item.revision_pending.revision &&
                        item.revision_pending.accepted
                      ? 'success'
                      : 'error'
                  "
                >
                  mdi-{{
                    item.revision_pending.revision == null
                      ? "alert"
                      : item.revision_pending.revision &&
                        item.revision_pending.accepted
                      ? "check"
                      : "close"
                  }}
                </v-icon>
                <div v-if="item.revision_pending.revision">
                  <span v-text="item.revision_pending.revision_user" />
                  <br />
                  <small v-text="item.revision_pending.revision_date" />
                </div>
              </div>
              <v-icon v-else small> mdi-alert </v-icon>
            </template>
            <template v-slot:item.revision_pending.confirmation_user="{ item }">
              <div v-if="item.revision_pending" class="text-center">
                <v-icon
                  small
                  :color="
                    item.revision_pending.confirmation == null
                      ? 'warning'
                      : item.revision_pending.confirmation &&
                        item.revision_pending.accepted
                      ? 'success'
                      : 'error'
                  "
                >
                  mdi-{{
                    item.revision_pending.confirmation == null
                      ? "alert"
                      : item.revision_pending.confirmation &&
                        item.revision_pending.accepted
                      ? "check"
                      : "close"
                  }}
                </v-icon>
                <div v-if="item.revision_pending.confirmation">
                  <span v-text="item.revision_pending.confirmation_user" />
                  <br />
                  <small v-text="item.revision_pending.confirmation_date" />
                </div>
              </div>
              <v-icon v-else small> mdi-alert </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    :to="{
                      name: `${page_route}.view`,
                      params: { id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-eye-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  base64ToArrayBuffer,
  dateTimeToFile,
} from "../../control";
import { index, show } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      contractor_id: this.$route.params.contractor_id,
      page_route: "asegurados",
      login: this.$store.getters.getLogin,
      loading: false,
      data: [],
      total_rows: 0,
      contractor: {},
      loading_contractor: true,
      search: "",
      headers: [
        { text: "#", filterable: false, value: "index" },
        { text: "N. asegurado", filterable: true, value: "credential" },
        { text: "ID SM", filterable: true, value: "enrollment" },
        { text: "Nombre", filterable: true, value: "name" },
        { text: "Apellido paterno", filterable: true, value: "first_surname" },
        { text: "Apellido materno", filterable: true, value: "second_surname" },
        { text: "Póliza", filterable: true, value: "policy" },
        { text: "Vigencia", filterable: true, value: "validity" },
        { text: "Tipo", filterable: true, value: "insured_type.insured_type" },
        {
          text: "R1",
          filterable: true,
          value: "revision_pending.revision_user",
        },
        {
          text: "R2",
          filterable: true,
          value: "revision_pending.confirmation_user",
        },
        { text: "T. méd.", filterable: false, value: "medical_treatment" },
        { text: "Usr. sis.", filterable: false, value: "user_id" },
        { text: "", value: "action", sortable: false },
      ],
      medical_treatment: 0,
      medical_treatments: [
        { id: 0, value: "TODOS" },
        { id: 1, value: "EN TRATAMIENTO" },
        { id: 2, value: "SIN TRATAMIENTO" },
      ],
      revision: null,
      revisions: [
        { id: null, value: "Selecciona una opción..." },
        { id: 0, value: "NO APLICA" },
        { id: 1, value: "REEMBOLSO" },
        { id: 2, value: "DATOS BANCARIOS | NO REG. PARA REEMBOLSO" },
        { id: 3, value: "DATOS BANCARIOS | REV 1 PENDIENTE" },
        { id: 4, value: "DATOS BANCARIOS | REV 2 PENDIENTE" },
        { id: 5, value: "DATOS BANCARIOS | REV. VALIDADA" },
        { id: 6, value: "DATOS BANCARIOS | REV. RECHAZADA" },
      ],
      btn_xls_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.data = [];

      if (this.revision != null) {
        this.loading = true;
        index("insureds/contractor/" + this.contractor_id, this.login.token, [
          {
            name: "medical_treatment",
            value: this.medical_treatment,
          },
          {
            name: "revision",
            value: this.revision,
          },
        ]).then((response) => {
          this.data = response.data;
          this.total_rows = this.data.length;
          this.loading = false;
        });
      }
    },
    handleView(row_id) {
      this.$router.push({
        name: `${this.page_route}.view`,
        params: { id: row_id },
      });
    },
    downloadXls() {
      this.btn_xls_ldg = true;

      Axios.post(
        URL_API + "/insureds/xls",
        {
          items: this.data,
        },
        headersToken(this.login.token)
      ).then((res) => {
        if (res.data.success) {
          this.fileDownload(res.data.data, "xlsx", "asegurados");
          this.btn_xls_ldg = false;
        } else {
          this.btn_xls_ldg = false;
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("contractors", this.login.token, this.contractor_id).then(
      (response) => {
        this.contractor = response;
        this.loading_contractor = false;
      }
    );
  },
};
</script>